import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import { displayStepperSlice } from '../state/displayStepperSlice';
import { maintTitleSlice } from '../state/mainTitleSlice';
import { mainSubtitleSlice } from '../state/mainSubtitleSlice';
import { hasErrorSlice } from '../state/hasErrorSlice';

import solutionEnLigne from '../assets/images/solution-en-ligne.jpg';
import solutionEnLigneMin from '../assets/images/solution-en-ligne-min.jpg';
import depannageADistanceMin from '../assets/images/depannage-a-distance-min.jpg';
import depannageADistance from '../assets/images/depannage-a-distance.jpg';
import parcelRelayIcon from '../assets/svg/parcel-relay.svg';
import SVG from 'react-inlinesvg';
import { BOUTIQUE, DIABOLO_COM_DOMAIN, DIABOLO_COM_KEY, POSTE, TC_EVENT_COMMON_DATA } from '../utils/consts';
import IPageTracking from '../interfaces/IPageTracking';
import { pushTcClicEvent } from '../utils/tc-utils';
import { selectNode } from '../state/nodeSlice';
import ChronopostTransport from '../components/chronopost-transport';

export default function KOSolutionMobile(): React.ReactElement {
  const dispatch = useAppDispatch();
  const node = useAppSelector(selectNode);
  const [selectedTab, setSelectedTab] = useState<string>(BOUTIQUE);
  let tcVars: IPageTracking = TC_EVENT_COMMON_DATA;

  useEffect(() => {
    dispatch(displayStepperSlice.actions.hide());
    dispatch(maintTitleSlice.actions.update('Je veux envoyer mon mobile en SAV'));
    dispatch(mainSubtitleSlice.actions.update({ desktop: 'Navrés que le diagnostic n’ait pas permis de résoudre votre panne 😞 . <br> Notre centre de réparation va examiner votre appareil pour comprendre le problème et tenter de le résoudre. Un devis vous sera envoyé si l’appareil n’est plus sous garantie, ou si le problème n’est pas couvert par les garanties (casse, oxydation…).', mobile: 'Navrés que le diagnostic n’ait pas permis de résoudre votre panne. Notre centre de réparation va prendre en charge votre appareil. Un devis vous sera envoyé en cas d’exclusion de garantie (casse, oxydation…).' }));
    dispatch(hasErrorSlice.actions.remove());
    addScriptDiaboloCom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    tcVars = Object.assign(tcVars, { page: 'Probleme_non_resolu_proposition_envoi_reparation_boutique', pageCategorie: 'Information' });
    window.tc_vars = tcVars;
    tc_events_2(window, 'data-layer-ready', tcVars);
  }, []);

  useEffect(() => {
    switch (selectedTab) {
      case BOUTIQUE:
        // eslint-disable-next-line react-hooks/exhaustive-deps
        tcVars = Object.assign(tcVars, { page: 'Probleme_non_resolu_proposition_envoi_reparation_boutique', pageCategorie: 'Information' });
        window.tc_vars = tcVars;
        tc_events_2(window, 'data-layer-ready', tcVars);
        break;
      case POSTE:
        tcVars = Object.assign(tcVars, { page: 'Probleme_non_resolu_proposition_envoi_reparation_relais_Pickup', pageCategorie: 'Information' });
        window.tc_vars = tcVars;
        tc_events_2(window, 'data-layer-ready', tcVars);
        break;
      default:
        break;
    }
  }, [selectedTab]);

  const addScriptDiaboloCom = () => {
    if (document.getElementById('dblcm-webcallback-modal')) {
      return;
    }
    const body = document.getElementsByTagName('body')[0];
    const scriptDiaboloCom = document.createElement('script');
    scriptDiaboloCom.type = 'text/javascript';
    scriptDiaboloCom.async = true;
    scriptDiaboloCom.src = DIABOLO_COM_DOMAIN + '/chat.js?scenario=' + DIABOLO_COM_KEY;
    body.appendChild(scriptDiaboloCom);
  };

  const handleClickToCall = () => {
    pushTcClicEvent('Etre_rappeler');
    const buttonDiabolo: HTMLElement = document.getElementsByClassName('webcallback-button')[0] as HTMLElement;
    buttonDiabolo.click();
  };

  return (
    <>
      <section className="section has-background-white">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-10-widescreen">
              <div className="rows">
                <div className="row">
                  <div className="tabs is-centered" data-tabs-context="">
                    <ul className="has-text-centered">
                      <li className="h-100" onClick={() => setSelectedTab(BOUTIQUE)}>
                        <a data-tab-navigation="" className={selectedTab === BOUTIQUE ? 'is-active h-100' : 'h-100'}>
                          <span className="icon-and-text is-stacked">
                            <span className="icon is-medium">
                              <i className="tri-store"></i>
                            </span>
                            <span className="tab-name is-hidden-mobile h-100" title="En boutique">En boutique</span>
                            <span className="tab-name is-hidden-tablet" title="En boutique">En boutique</span>
                          </span>
                        </a>
                      </li>
                      <li className="h-100" onClick={() => setSelectedTab(POSTE)}>
                        <a data-tab-navigation="" className={selectedTab === POSTE ? 'is-active' : ''}>
                          <span className="icon-and-text is-stacked">
                            <span className="icon is-medium">
                              <SVG src={parcelRelayIcon} height='32px'/>
                            </span>
                            <span className="tab-name is-hidden-mobile h-100" title="En relais Pickup">En relais Pickup</span>
                            <span className="tab-name is-hidden-tablet" title="En relais Pickup">En relais Pickup</span>
                          </span>
                        </a>
                      </li>
                    </ul>
                    <div data-tabs-wrapper="">
                      <div data-tab-content="" className={selectedTab === BOUTIQUE ? 'is-active' : ''}>
                        <br/>
                        <div className="rows">
                          <div className="row">
                            <div className="columns is-centered has-text-centered-tablet">
                              <div className="column is-3">
                                <div className="columns is-mobile is-multiline is-aligned-center">
                                  <div className="column is-12-widescreen is-12-tablet is-3-mobile">
                                    <span className="icon is-large">
                                      <i className="tri-store"></i>
                                    </span>
                                  </div>
                                  <div className="column is-12-widescreen is-9-mobile">
                                    <p><span className="has-text-weight-semibold">1. Je dépose</span> mon appareil <span className="nowrap">en boutique</span><br></br>Si je suis éligible, je pourrai bénéficier d’un téléphone de prêt</p>
                                  </div>
                                </div>
                              </div>
                              <div className="column is-3">
                                <div className="columns is-mobile is-multiline is-aligned-center">
                                  <div className="column is-12-widescreen is-12-tablet is-3-mobile">
                                    <span className="icon is-large">
                                      <i className="tri-barcode-search"></i>
                                    </span>
                                  </div>
                                  <div className="column is-12-widescreen is-9-mobile">
                                    <p><span className="has-text-weight-semibold">2. Je consulte</span> en ligne l’état d’avancement de ma prise en charge SAV</p>
                                  </div>
                                </div>
                              </div>
                              <div className="column is-3">
                                <div className="columns is-mobile is-multiline is-aligned-center">
                                  <div className="column is-12-widescreen is-3-mobile">
                                    <span className="icon is-large">
                                      <i className="tri-store"></i>
                                    </span>
                                  </div>
                                  <div className="column is-12-widescreen is-12-tablet is-9-mobile">
                                    <p><span className="has-text-weight-semibold">3. Je réceptionne</span> mon appareil en boutique, après avoir été averti par SMS ou e mail</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="buttons is-centered">
                              <a className="button is-primary" href="https://boutiques.bouyguestelecom.fr/bouyguestelecom/fr"
                                onClick={() => pushTcClicEvent('Depot_boutique')}>Je trouve une boutique</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div data-tab-content="" className={selectedTab === POSTE ? 'is-active' : ''} >
                        <br/>
                        <div className="rows">
                          <div className="row">
                            <div className="columns is-centered has-text-centered-tablet">
                              <div className="column">
                                <div className="columns is-mobile is-multiline is-aligned-center">
                                  <div className="column is-12-widescreen is-12-tablet is-3-mobile">
                                    <span className="icon is-large">
                                      <i className="tri-printer"></i>
                                    </span>
                                  </div>
                                  <div className="column is-12-widescreen is-9-mobile">
                                    <p><span className="has-text-weight-semibold">1. J’imprime l’étiquette et le bulletin d’intervention</span></p>
                                  </div>
                                </div>
                              </div>
                              <div className="column">
                                <div className="columns is-mobile is-multiline is-aligned-center">
                                  <div className="column is-12-widescreen is-12-tablet is-3-mobile">
                                    <span className="icon is-large">
                                      <i className="tri-parcel"></i>
                                    </span>
                                  </div>
                                  <div className="column is-12-widescreen is-9-mobile">
                                    <p><span className="has-text-weight-semibold">2. Je prépare le colis</span><br />Je range soigneusement mon téléphone dans un carton dépourvu de toute autre étiquette</p>
                                  </div>
                                </div>
                              </div>
                              <div className="column">
                                <div className="columns is-mobile is-multiline is-aligned-center">
                                  <div className="column is-12-widescreen is-3-mobile">
                                    <span className="icon is-large">
                                      <SVG src={parcelRelayIcon}/>
                                    </span>
                                  </div>
                                  <div className="column is-12-widescreen is-12-tablet is-9-mobile">
                                    <p><span className="has-text-weight-semibold">3. Je dépose le colis</span><br />dans un relais Pickup dans les <span className="has-text-weight-semibold">7 jours suivants ma demande</span></p>
                                  </div>
                                </div>
                              </div>
                              <div className="column">
                                <div className="columns is-mobile is-multiline is-aligned-center">
                                  <div className="column is-12-widescreen is-3-mobile">
                                    <span className="icon is-large">
                                      <i className="tri-barcode-search"></i>
                                    </span>
                                  </div>
                                  <div className="column is-12-widescreen is-12-tablet is-9-mobile">
                                    <p><span className="has-text-weight-semibold">4. Je consulte en ligne</span><br />l&apos;état d&apos;avancement de ma prise en charge SAV</p>
                                  </div>
                                </div>
                              </div>
                              <div className="column">
                                <div className="columns is-mobile is-multiline is-aligned-center">
                                  <div className="column is-12-widescreen is-3-mobile">
                                    <span className="icon is-large">
                                      <SVG src={parcelRelayIcon}/>
                                    </span>
                                  </div>
                                  <div className="column is-12-widescreen is-12-tablet is-9-mobile">
                                    <p><span className="has-text-weight-semibold">5. Je réceptionne mon appareil</span> dans un relais Pickup, après avoir été averti par SMS ou email</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br/>
                          <div className="row">
                            <div className="buttons is-centered">
                              <a className="button is-primary" href={`https://www.sav.bouyguestelecom.fr/sav/eligibilite${null === node?.anomalyCode || undefined === node?.anomalyCode || '' === node?.anomalyCode ? '' : `?panne=${node?.anomalyCode}`}`} onClick={() => pushTcClicEvent('Envoi_centre_reparation')}>J&apos;envoie mon appareil</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {selectedTab === POSTE && <ChronopostTransport />}
      <section className="section is-medium has-background-white">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-11-widescreen">
              <div className="rows">
                <div className="row">
                  <div className="columns is-centered is-aligned-center">
                    <div className="column is-5 is-hidden-mobile">
                      <div className="card is-floating">
                        <div className="card-image">
                          <figure className="image">
                            <img src={solutionEnLigne} alt="Solution en ligne" loading="lazy"/>
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className="column is-5 is-offset-1">
                      <h2 className="title is-size-2-tablet">D&apos;autres solutions en ligne existent</h2>
                      <div className="card is-floating is-hidden-tablet">
                        <div className="card-image">
                          <figure className="image">
                            <img src={solutionEnLigneMin} alt="Solution en ligne" loading="lazy"/>
                          </figure>
                        </div>
                      </div>
                      <br/>
                      <div className="columns">
                        <div className="column">
                          <div className="columns is-mobile is-multiline">
                            <div className="column is-12-desktop is-12-tablet is-3-mobile">
                              <span className="icon is-large">
                                <i className="tri-hand-tools"></i>
                              </span>
                            </div>
                            <div className="column is-12-desktop is-12-tablet is-9-mobile">
                              <p className="text is-2">On a réuni les meilleures réponses aux questions qu’on nous pose souvent</p>
                              <p><a className="is-static link" href="https://www.assistance.bouyguestelecom.fr/s/" onClick={() => pushTcClicEvent('Consulter_assistance')}>Consulter l&apos;assistance</a></p>
                            </div>
                          </div>
                        </div>
                        <div className="column">
                          <div className="columns is-mobile is-multiline">
                            <div className="column is-12-desktop is-12-tablet is-3-mobile">
                              <span className="icon is-large">
                                <i className="tri-comments-question-dots"></i>
                              </span>
                            </div>
                            <div className="column is-12-desktop is-12-tablet is-9-mobile">
                              <p className="text is-2">Le forum peut contenir votre réponse</p>
                              <br className="is-hidden-mobile"/>
                              <p><a className="is-static link" href="https://www.bouyguestelecom.fr/search?q=forum&origin=home" onClick={() => pushTcClicEvent('Consulter_forum')}>J&apos;y vais</a></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br/>
                <div className="row">
                  <div className="columns is-centered is-aligned-center">
                    <div className="column is-5">
                      <h2 className="title is-size-2-tablet">J&apos;aimerais profiter d&apos;un dépannage à distance pour mon Mobile</h2>
                      <div className="card is-floating is-hidden-tablet">
                        <div className="card-image">
                          <figure className="image">
                            <img src={depannageADistanceMin} alt="Solution en ligne" loading="lazy"/>
                          </figure>
                        </div>
                      </div>
                      <br/>
                      <p className="text is-2">
                        Je me fais rappeler par un technicien SAV Mobile, qui m’aidera à essayer de résoudre mon
                        problème à distance.
                        <br/>
                        Pour toutes autres problématiques, nous vous invitons à consulter la page <a className="is-static link" href="https://www.assistance.bouyguestelecom.fr/s/contact">Nous contacter</a>
                      </p>
                      <div className="buttons">
                        <a className="button is-secondary" onClick={() => handleClickToCall()}>Me faire rappeler</a>
                      </div>
                    </div>
                    <div className="column is-5 is-hidden-mobile is-offset-1">
                      <div className="card is-floating">
                        <div className="card-image">
                          <figure className="image">
                            <img src={depannageADistance} alt="Solution en ligne" loading="lazy"/>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
